<template>
  <div class="home-wrap">
    <!-- <button @click="play">播放</button> -->
    <div id="wrapp" />
    <div class="home-header">
      <img class="home-img" src="../assets/al.png" alt />

      <span class="home-LOGO" v-if="hotelName">{{ HotelName }}</span>
      <div class="home-function">
        <!-- <i class="el-icon-search"></i>
        <i class="el-icon-bell"></i> -->
        <div class="portrait">
          <div class="head-portrait">
            <img src="../assets/ic_date.png" alt />
          </div>
          <span style="margin-right: 10px">{{ user.deptCode }}</span>
          <span>{{ user.userName }}</span>
          <div class="newMessage" v-if="messageNumber && messageNumber != 0">
            <img src="../img/ic_bell.png" alt />
            <!-- 1 -->
          </div>
          <div class="newMessage2" v-else></div>
        </div>
        <div>
          <el-button type="primary" @click="changeHotel">切换酒店</el-button>
        </div>
        <div>
          <el-button type="danger" @click="goOut">退出</el-button>
        </div>
      </div>
    </div>
    <div class="home-container">
      <div class="home-left">
        <el-menu
          style="background: #fff"
          :default-active="faultPage"
          class="el-menu-vertical-demo"
          :default-openeds="defaultOpeneds"
          @open="handleOpen"
        >
          <el-submenu
            v-for="(item, index1) in moduleList"
            :key="index1"
            :index="item.parentModuleNo"
          >
            <template slot="title">
              <!-- <i
                class="el-icon-setting"
                v-if="item.parentModuleName == '系统管理'"
              ></i>
              <i
                class="el-icon-document"
                v-if="item.parentModuleName == '基础信息管理'"
              ></i>
              <i
                class="el-icon-info"
                v-if="item.parentModuleName == '工作台'"
              ></i> -->
              <img
                src="../img/icon1.png"
                alt
                style="margin-right: 10px"
                v-if="item.parentModuleName == '系统管理'"
              />
              <img
                src="../img/icon2.png"
                style="margin-right: 10px"
                alt
                v-if="item.parentModuleName == '空调设置'"
              />
              <img
                src="../img/icon3.png"
                style="margin-right: 10px"
                alt
                v-if="item.parentModuleName == '房间设置'"
              />
              <img
                src="../img/icon4.png"
                style="margin-right: 10px"
                alt
                v-if="item.parentModuleName == '其他设置'"
              />
              <img
                src="../img/icon5.png"
                style="margin-right: 10px"
                alt
                v-if="item.parentModuleName == '工作台'"
              />
              <span>{{ item.parentModuleName }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(item2, index2) in item.children"
                :key="index2"
                :index="item2.moduleNo"
                @click="menu(item2)"
                style="display: flex"
              >
                <div
                  v-if="
                    item2.moduleName == '提醒消息' && messageList.length > 0
                  "
                  class="ic_bell"
                >
                  <!-- <img src="../img/ic_bell.png" alt /> -->
                </div>

                <div v-else class="ic_bell"></div>
                {{ item2.moduleName }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
      <div class="home-right">
        <router-view />
      </div>
    </div>
    <!--选择酒店 -->
    <!-- <el-dialog title="选择酒店" :visible.sync="dialogVisible" width="45%">
      <el-radio-group v-model="hotel">
        <el-radio-button
          v-for="item in hotelList"
          :key="item.id"
          :value="item.id"
          :label="item.id"
          >{{ item.hotelName }}</el-radio-button
        >
      </el-radio-group>

      <span slot="footer" class="dialog-footer">
        <el-button class="anniu" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button class="anniu" type="primary" @click="choseHotel"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <transition name="bangzhu">
      <div
        v-if="!hidetongzhi && messageList.length > 0"
        :class="{ tfNotice_box: isActive, heightLimited: heightLimit }"
      >
        <div id="tzmessage" class="tfNotice">
          <div
            class="Notices"
            v-for="(item, index) in messageList"
            :key="index"
          >
            <div class="Notices_title">
              <!-- <h4 class="title1">
                {{ item.roomno }}
                <span v-if="item.read == 0" style="font-size: 14px; color: red"
                  >New</span
                >
              </h4> -->
            </div>
            <div class="Notice_contain">
              <div class="Notice_dotype">
                <span
                  v-if="item.message != 'SOSService'"
                  @click="deleteInfor(item, index)"
                  class="delmessage"
                  >×</span
                >
                <span class="hotelName">{{ item.roomNo }}</span>
                <span v-if="item.message == 'roomStatus'">房态变更</span>
                <span v-else-if="item.message == 'SOSService'">紧急呼叫</span>
                <span v-else>服务通知</span>
              </div>
            </div>
            <div
              :class="['Notice_user', { danger: item.message == 'SOSService' }]"
            >
              {{ item.content }}
            </div>
          </div>
          <!-- <div class="Notices" v-if="messageList.length > 7">
            <div class="Notices_title">
              <h4 class="title"></h4>
              <span></span>
            </div>
            <div class="Notice_user"></div>
          </div> -->
        </div>
        <div class="header">
          <div class="tzcount">
            共计：{{ messageList.length }} 条
            <!-- <a @click="qingkongtz" class="qingkong">清空</a> -->
          </div>
          <div @click="hideMessage" class="sqtz">收起通知</div>
          <!-- <div class="hidetz" @click="shouqitongzhi">收起通知</div> -->
        </div>
      </div>
    </transition>
    <div
      class="float"
      @click="opeanMessage"
      v-if="hidetongzhi && messageList.length > 0"
    >
      打开通知
    </div>
    <!-- <img src="../img/shouqi" class="float" @click="noShow" alt="" /> -->

    <el-dialog title="切换酒店" :visible.sync="choseHotelVisable" width="45%">
      <div>
        <el-radio-group v-model="hotelToBe">
          <el-radio-button
            v-for="item in choseHotelList"
            :key="item.id"
            :value="item.id"
            :label="item.id"
            >{{ item.hotelName }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="choseHotelVisable = false">取 消</el-button>
        <el-button type="primary" @click="quedingChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import global from "@/Global.vue";
// import Speech from "speak-tts";
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
import { postRequest, getRequest } from "@/api/api.js";
export default {
  name: "SpeechDemo",
  data() {
    return {
      path: global.webSocket,
      dialogVisible: true,
      hotelList: [],
      hotel: null,
      hotelToBe: null,
      selectHotel: {},
      shouye: true,
      qxmk: true,
      gly: true,
      jdgl: true,
      xcx: true,
      jdqx: true,
      user: {},
      selectHotel: {},
      moduleList: [],
      HotelName: null,
      isActive: true,
      heightLimit: false,
      hidetongzhi: false,
      messageList: [],
      heartCheckNum: null,
      timer: null,
      timer2: null,
      list: [],
      hotelName: false,
      save: false,
      list1: [],
      list2: [],
      list3: [],
      add: null,
      roomList: [],
      messageNumber: null,
      // 跳转页面
      faultPage: "null",
      defaultOpeneds: [],
      speech: null,
      click: true,
      speakTimer: null,
      redFlag: true,
      choseHotelList: [],
      choseHotelVisable: false,
    };
  },

  created() {
    this.getRoom2();
    this.$nextTick(() => {
      setInterval(this.play, 1000);
    });
    this.roomList = this.$store.state.roomList;
    let hotellist = JSON.parse(window.localStorage.getItem("hotelList"));
    if (hotellist.length > 0) {
      this.hotelName = true;
    } else {
      this.hotelName = false;
    }
    this.WebSocketTest();
    this.$store.state.messageList = [];
    this.messageList = [];
    this.list = [];
    this.messageList = this.$store.state.messageList;
    let hotel = JSON.parse(window.localStorage.getItem("hotelInfor"));
    this.HotelName = hotel.hotelName;

    this.user = JSON.parse(window.localStorage.getItem("user"));
    this.hotelList = JSON.parse(window.localStorage.getItem("hotelList"));

    this.moduleList = JSON.parse(window.localStorage.getItem("moduleList"));
    // 首页
    //this.shouye = this.power.checkedpower("00101");
    // 权限模块
    //this.qxmk = this.power.checkedpower("00201");
    // 系统管理员
    //this.gly = this.power.checkedpower("00301");
    // 酒店管理
    //this.jdgl = this.power.checkedpower("00401");
    // 小程序版本
    //this.xcx = this.power.checkedpower("00501");
    // 酒店权限模块
    //this.jdqx = this.power.checkedpower("00601");
    // let superAdmin = sessionStorage.getItem("superAdmin");
    // if (superAdmin == 1) {
    //   this.superAdmin = true;
    // }
    let findPage = JSON.parse(window.localStorage.getItem("findPage"));
    if (findPage == 0) {
      this.find();
      window.localStorage.removeItem("findPage");
    }

    // 查询信息
    this.getMessageLength();
  },

  computed: {},

  watch: {
    cusercode() {
      this.WebSocketTest();
    },
  },
  destroyed: {},
  mounted() {
    //定时任务方法
    this.$nextTick(() => {
      setInterval(this.getMessageLength, 50000);
    });
    // this.$nextTick(() => {
    //   setInterval(this.getCache, 1000);
    // });
    // this.$nextTick(() => {
    //   setInterval(this.getRoom, 1000);
    // });
  },
  methods: {
    // 语音播报的函数
    handleSpeak(text) {
      msg.text = text; // 文字内容
      msg.lang = "zh-CN"; // 使用的语言:中文
      msg.volume = 1; // 声音音量：1
      msg.rate = 1; // 语速：1
      msg.pitch = 1; // 音高：1
      synth.speak(msg); // 播放
    },
    //     stopSpeak(){
    // if()
    //     },
    // 语音停止
    handleStop(e) {
      msg.text = e;
      msg.lang = "zh-CN";
      synth.cancel(msg);
    },
    changeHotel() {
      postRequest("getHotels", {}).then((res) => {
        if (res.status == 200) {
          localStorage.setItem("hotelList", JSON.stringify(res.data));
          this.$router.push("/choseHotel");
          this.handleStop();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    quedingChange() {
      this.choseHotelList.forEach((item) => {
        if (item.id == this.hotelToBe) {
          this.selectHotel.hotelId = item.id;
          this.selectHotel.hotelName = item.hotelName;
        }
      });
      this.selectHotel.userCode = this.user.userCode;
      this.selectHotel.userName = this.user.userName;
      postRequest("/selectHotel", this.selectHotel).then((res) => {
        if (res.status == 200) {
          this.HotelName = res.data.hotelName;
          localStorage.setItem("hotelInfor", JSON.stringify(res.data));
          localStorage.setItem("findPage", 0);
          this.getRoom();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
    getRoom2() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          let list = [];
          res.data.forEach((item) => {
            item.serviceStatusList.forEach((item2) => {
              if (
                item2.valueCode == "TurnOn" &&
                item2.deviceTypeCode != "DNDService"
              ) {
                console.log(item2, item);
                var message = {};
                message.roomNo = item.roomNo;
                message.serviceType = item2.deviceTypeCode;
                message.content = item.roomNo + "请求" + item2.deviceTypeName;
                if (
                  item2.deviceTypeCode == "ClearService" ||
                  item2.deviceTypeCode == "CheckOutService"
                ) {
                  message.message = "otherService";
                }
                if (item2.deviceTypeCode == "SOSService") {
                  message.message = "SOSService";
                }
                list.push(message);
                localStorage.setItem("roomIcon", JSON.stringify(list));
                localStorage.setItem("roomIconClose", "");
                this.addMessage(message);
              }
            });
          });
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    getRoom() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            console.log(444, item);
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          this.$store.state.roomList = [];
          this.$store.state.roomList = res.data;
          this.$router.push("/roomControl");
          this.choseHotelVisable = false;
          //
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    // 获取缓存图标和背景
    getCache() {
      let roomStatus = JSON.parse(window.localStorage.getItem("roomStatus"));
      let roomIcon = JSON.parse(window.localStorage.getItem("roomIcon"));
      let roomIconClose = JSON.parse(
        window.localStorage.getItem("roomIconClose")
      );
      if (roomStatus && roomStatus.length > 0) {
        roomStatus.forEach((statusItem) => {
          this.roomList.forEach((item) => {
            if (statusItem.roomNo == item.roomNo) {
              item.roomStatus = statusItem.serviceType;
            }
          });
        });
      }

      if (this.$store.state.add == 0) {
        if (roomIcon && roomIcon.length > 0) {
          roomIcon.forEach((IconItem) => {
            this.roomList.forEach((item, index1) => {
              if (IconItem.roomNo == item.roomNo) {
                item.serviceStatusList.forEach((item2, index2) => {
                  if (
                    item2.deviceTypeCode == IconItem.serviceType &&
                    item2.roomId == IconItem.roomId
                  ) {
                    let res = this.roomList[index1].serviceStatusList[index2];
                    console.log(res);
                    res.valueCode = "TurnOn";
                    item.Flag = 1;
                  }
                  // else {
                  //   item2.valueCode = "TurnOff";
                  // }
                });
              }
            });
          });
        }
        // if (roomIcon.length == 0) {
        //   console.log(6666);
        //   this.roomList.forEach((item) => {
        //     item.serviceStatusList.forEach((item2) => {
        //       item2.valueCode = "TurnOff";
        //     });
        //   });
        // }
      }
      // if (this.$store.state.add == 1) {
      if (roomIconClose && roomIconClose.length > 0) {
        roomIconClose.forEach((item1) => {
          this.roomList.forEach((item2) => {
            if (item1.roomNo == item2.roomNo) {
              item2.serviceStatusList.forEach((item3) => {
                if (item3.deviceTypeCode == item1.serviceType) {
                  item3.valueCode = "TurnOff";
                }
              });
            }
          });
        });
        // }
      }
      let list1 = this.$store.state.roomList;
      // let list1 = window.localStorage.getItem("roomList");
      list1.forEach((item1) => {
        this.roomList.forEach((item2) => {
          if (item1.roomNo == item2.roomNo) {
            item1 = item2;
          }
        });
      });
      // localStorage.setItem("roomList", JSON.stringify(list1));
      this.$store.state.roomList = list1;
    },
    WebSocketTest() {
      this.num = 0;
      this.heartCheckNum = 0;
      if ("WebSocket" in window) {
        // alert("您的浏览器支持 WebSocket!");
        // 打开一个 web socket
        this.socket = new WebSocket(this.path);
        this.socket.onopen = this.open;
        this.socket.onmessage = this.getMessage;
        this.socket.onerror = this.error;
      } else {
        // 浏览器不支持 WebSocket
        alert("您的浏览器不支持 WebSocket!");
      }
    },
    hideMessage() {
      this.hidetongzhi = true;
      console.log(555);
    },
    opeanMessage() {
      this.hidetongzhi = false;
    },
    noShow() {
      this.hidetongzhi = false;
    },

    getMessage(msg) {
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      let user = JSON.parse(window.localStorage.getItem("user"));
      if (
        msg.data ==
        "heartcheck" + hotelInfor.hotelId + "-" + user.userCode + "PC"
      ) {
        this.heartCheckNum++;
        return;
      }
      if (msg.data != "服务器连接成功！") {
        let obj = JSON.parse(msg.data);
        console.log(msg.data);
        // this.getFounderList();
        if (obj.opFlag == "DISPLAY") {
          if (obj.message == "otherService") {
            var message = {};
            message.roomNo = obj.data.roomNo;
            message.hotelName = obj.data.hotelName;
            message.content = obj.data.content;
            message.messageId = obj.data.messageId;
            message.message = obj.message;
            message.serviceType = obj.data.serviceType;
            this.addMessage(message);
          }
          // 1同步房态
          // if (this.save == true) {
          if (obj.message == "roomStatus") {
            var message = {};
            message.roomNo = obj.data.roomNo;
            message.hotelName = obj.data.hotelName;
            message.content = obj.data.content;
            message.messageId = obj.data.messageId;
            message.message = obj.message;
            message.serviceType = obj.data.serviceType;
            this.addMessage(message);
            // 同步背景
            this.list1.push(obj.data);
            localStorage.setItem("roomStatus", JSON.stringify(this.list1));
          } else {
            // 同步底部
            var message = {};
            message.roomNo = obj.data.roomNo;
            message.hotelName = obj.data.hotelName;
            message.content = obj.data.content;
            message.messageId = obj.data.messageId;
            message.message = obj.message;
            message.serviceType = obj.data.serviceType;
            this.addMessage(message);
            this.$store.state.add = 0;
            if (obj.opFlag == "DISPLAY") {
              this.list2 = [];
              this.list2.push(obj.data);
              if (this.list2.length > 0) {
                for (let i = 0; i < this.list2.length - 1; i++) {
                  for (let j = i + 1; j < this.list2.length; j++) {
                    if (
                      this.list2[i].serviceType == this.list2[j].serviceType &&
                      this.list2[i].roomNo == this.list2[j].roomNo &&
                      this.list2[i].roomId == this.list2[j].roomId
                    ) {
                      this.list2.splice(j, 1);
                      j--;
                    }
                  }
                }
              }
              localStorage.setItem("roomIcon", JSON.stringify(this.list2));
            }
          }
          this.list3 = [];
          localStorage.setItem("roomIconClose", JSON.stringify(this.list3));
        }
        if (obj.opFlag == "CLOSE") {
          this.handleStop();
          // 1同步房态
          if (obj.message == "otherService") {
            this.deleteIt(obj.data);
            this.$store.state.add = 1;
            this.list3.push(obj.data);
            localStorage.setItem("roomIconClose", JSON.stringify(this.list3));
          }
          // if (this.save == true) {
          if (obj.message == "roomStatus") {
            // 同步背景
            this.list1.push(obj.data);
            localStorage.setItem("roomStatus", JSON.stringify(this.list1));
          } else {
            // 同步底部
            this.$store.state.add = 0;
            console.log(333, obj);
            this.deleteIt(obj.data);
          }
          // }
        }
        let roomIcon = JSON.parse(window.localStorage.getItem("roomIcon"));
        if (obj.opFlag == "CLOSE" && roomIcon) {
          roomIcon.forEach((item, index) => {
            if (
              item.roomNo == obj.data.roomNo &&
              item.serviceType == obj.data.serviceType
            ) {
              roomIcon.splice(index, 1);
            }
          });
          localStorage.setItem("roomIcon", JSON.stringify(roomIcon));
        }
        if (obj.message == "SOSService" && obj.opFlag == "CLOSE") {
          this.$store.state.add = 1;
          this.list3.push(obj.data);
          localStorage.setItem("roomIconClose", JSON.stringify(this.list3));
          this.handleStop();
          console.log(obj);
          // if (!obj.messageId) {
          //   alert(1);
          //   this.messageList.forEach((item) => {
          //     if (
          //       item.roomNo == obj.data.roomNo &&
          //       item.message == obj.data.serviceType
          //     ) {
          //       this.messageList.splice(item, 1);
          //     }
          //   });
          // } else {
          //   alert(2);
          this.deleteIt(obj.data);
          // }
        }
        // this.getRoom2()
        return;
      }
    },
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let condition = {
        offset: 1,
        num: 10,
      };
      postRequest("selectAllHotelRoom", condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$store.state.roomTableList = res.data.list;
          this.$store.state.roomTableTotal = res.data.total;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
      // }
    },
    addMessage(message) {
      this.list.push(message);
      console.log(4, this.list);
      this.$store.state.messageList = this.list;
      this.messageList = this.$store.state.messageList;
      for (let i = 0; i < this.messageList.length - 1; i++) {
        for (let j = i + 1; j < this.messageList.length; j++) {
          if (
            this.messageList[i].roomNo == this.messageList[j].roomNo &&
            this.messageList[i].serviceType == this.messageList[j].serviceType
          ) {
            this.messageList.splice(j, 1);
            j--;
          }
        }
      }
      console.log(this.messageListt);

      this.play();
      this.handleStop();
    },
    // 语音播报
    play() {
      let hotel = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.HotelName = hotel.hotelName;
      // console.log(this.messageList);
      this.messageList.forEach((item, index) => {
        // console.log("abc", index, item.content);
        let res = "";
        let res2 = "";
        let all = "";
        for (const key in item.content) {
          console.log(3333, item.content[key]);
          if (
            item.content[key] == "0" ||
            item.content[key] == "1" ||
            item.content[key] == "2" ||
            item.content[key] == "3" ||
            item.content[key] == "4" ||
            item.content[key] == "5" ||
            item.content[key] == "6" ||
            item.content[key] == "7" ||
            item.content[key] == "8" ||
            item.content[key] == "9"
          ) {
            res += item.content[key] + " ";
          } else {
            res2 += item.content[key];
          }
          all = res + res2;
        }
        var msg = new SpeechSynthesisUtterance(all);
        speechSynthesis.speak(msg);
      });
    },
    deleteIt(obj) {
      let list = this.messageList;
      list.forEach((item, index) => {
        console.log(item, obj);
        if (item.roomNo == obj.roomNo && item.message == obj.serviceType) {
          list.splice(index, 1);
        }
        console.log(999999, item, obj);
        if (item.roomNo == obj.roomNo && item.serviceType == obj.serviceType) {
          list.splice(index, 1);
        }
      });
      // console.log(3333, list);
      // return;

      this.messageList = list;
      this.play();
      this.handleStop();
    },
    open() {
      this.send();
      this.send2();
    },
    send() {
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      let user = JSON.parse(window.localStorage.getItem("user"));
      this.socket.send(
        JSON.stringify({
          uid: hotelInfor.hotelId + "-" + user.userCode,
        })
      );
    },
    send2() {
      let that = this;
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      let user = JSON.parse(window.localStorage.getItem("user"));
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        that.socket.send(
          JSON.stringify({
            heartcheck:
              "heartcheck" + hotelInfor.hotelId + "-" + user.userCode + "PC",
          })
        );
        that.num++;
        that.compare();
      }, 30000); //30000
    },
    goOut() {
      this.$confirm("确定退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/Login");
          var storage = window.localStorage;
          storage.clear();
          this.handleStop();
        })
        .catch(() => {
          //几点取消的提示
        });
    },
    compare() {
      let that = this;
      if (this.timer2) {
        clearTimeout(this.timer2);
      }
      this.timer2 = setTimeout(() => {
        if (that.num != that.heartCheckNum) {
          that.WebSocketTest();
        }
      }, 5000);
    },
    delMessage(index) {
      this.$store.state.messageList.splice(index, 1);
      this.$store.state.messageReadCount = this.$store.state.messageList.length;
      this.messageList = this.$store.state.messageList;
      this.handleStop();
    },
    // 'SOSService'
    deleteInfor(item, index) {
      if (!item.messageId) {
        // console.log(11)
        // return
        let list = this.$store.state.messageList;
        list.forEach((item1, index) => {
          // console.log(item,item1)
          // return
          if (
            item1.roomNo == item.roomNo &&
            item1.serviceType == item.serviceType
          ) {
            list.splice(index, 1);
            this.handleStop();
            // this.list3.push(item);
            // localStorage.setItem("roomIconClose", JSON.stringify(this.list3));
          }
        });
        this.$store.state.messageList = list;
        this.messageList = this.$store.state.messageList;
      } else {
        console.log(22);
        // return;
        let ids = [];
        ids.push(item.messageId);
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("changeReadDeviceLog", ids).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: "成功关闭!",
              type: "success",
            });
            this.messageList.splice(index, 1);
            // this.$store.state.messageReadCount =
            //  this.messageList.length;
            // this.messageList = this.$store.state.messageList;
            this.handleStop();
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        });
      }
    },
    handleOpen(index) {},
    // 选择酒店
    choseHotel() {
      if (this.hotel == null) {
        this.$message({
          duration: 5000,
          message: "请选择酒店！",
          center: true,
        });
        return;
      }
      this.hotelList.forEach((item) => {
        if (item.id == this.hotel) {
          this.selectHotel.hotelId = item.id;
          this.selectHotel.hotelName = item.hotelName;
        }
      });
      this.selectHotel.userCode = this.user.userCode;
      this.selectHotel.userName = this.user.userName;
      postRequest("/selectHotel", this.selectHotel).then((res) => {
        if (res.status == 200) {
          this.dialogVisible = false;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
    find() {
      this.moduleList.forEach((item) => {
        item.children.forEach((item2) => {
          let partModuleNo = item2.moduleNo.slice(0, 2);
          // return;
          if (partModuleNo == "05") {
            if (item2.moduleNo == "0501") {
              this.faultPage = "0501";
              this.$router.push("/roomControl");
              this.defaultOpeneds = ["05"];
              this.save = true;
            } else {
              this.faultPage = item.children[0].moduleNo;
              this.menu(item.children[0]);
            }
          }
          if (partModuleNo == "01") {
            if (item2.moduleNo == "0102") {
              this.faultPage = "0102";
              this.$router.push("/hotel");
              this.defaultOpeneds = ["01"];
            } else {
              this.faultPage = item.children[0].moduleNo;
              this.menu(item.children[0]);
            }
          }

          if (item2.moduleNo == "0201") {
            this.faultPage = item.children[0].moduleNo;
            this.defaultOpeneds = ["02"];
            this.menu(item.children[0]);
          }
        });
      });
    },
    menu(item) {
      this.getFounderList();
      if (item.moduleNo == "0101") {
        this.$router.push("/caidan");
      }
      if (item.moduleNo == "0102") {
        this.$router.push("/hotel");
      }
      if (item.moduleNo == "0103") {
        this.$router.push("/user");
      }
      if (item.moduleNo == "0104") {
        this.$router.push("/roomState");
      }
      if (item.moduleNo == "0105") {
        this.$router.push("/cstype");
      }
      if (item.moduleNo == "0106") {
        this.$router.push("/sbTypeManage");
      }
      if (item.moduleNo == "0107") {
        this.$router.push("/controlModel");
      }
      if (item.moduleNo == "0108") {
        this.$router.push("/deviceAliases");
      }
      if (item.moduleNo == "0109") {
        this.$router.push("/powerCard");
      }
      if (item.moduleNo == "0110") {
        this.$router.push("/TvChannelModel");
      }

      if (item.moduleNo == "0201") {
        this.$router.push("/seasonalConfig");
      }
      if (item.moduleNo == "0202") {
        this.$router.push("/summerCondit");
      }
      if (item.moduleNo == "0203") {
        this.$router.push("/winterCondit");
      }
      if (item.moduleNo == "0301") {
        this.$router.push("/buildingConfig");
      }
      if (item.moduleNo == "0302") {
        this.$router.push("/floorManage");
      }
      if (item.moduleNo == "0303") {
        this.$router.push("/roomArea");
      }
      if (item.moduleNo == "0304") {
        this.$router.push("/roomTypeManage");
      }
      if (item.moduleNo == "0305") {
        this.$router.push("/room");
      }

      if (item.moduleNo == "0401") {
        this.$router.push("/powerCardMange");
      }
      if (item.moduleNo == "0402") {
        this.$router.push("/roomStatelinkage");
      }
      if (item.moduleNo == "0403") {
        this.$router.push("/scheduledTask");
      }
      if (item.moduleNo == "0404") {
        this.$router.push("/TvChannel");
      }
      if (item.moduleNo == "0501") {
        this.$router.push("/roomControl");
        this.getCache();
        this.save = true;
      } else {
        this.save = false;
      }
      if (item.moduleNo == "0502") {
        this.$router.push("/message");
      }

      return;

      if (item.moduleNo == "0209") {
        this.$router.push("/room");
      }
      if (item.moduleNo == "0301") {
        this.$router.push("/roomControl");
        this.getCache();
        this.save = true;
      } else {
        this.save = false;
        // localStorage.setItem("roomStatus", "null");
        // localStorage.setItem("roomIcon", "null");
      }
      if (item.moduleNo == "0210") {
        this.$router.push("/powerCard");
      }
      if (item.moduleNo == "0211") {
        this.$router.push("/powerCardMange");
      }
      if (item.moduleNo == "0212") {
        this.$router.push("/roomStatelinkage");
      }
      if (item.moduleNo == "0213") {
        this.$router.push("/scheduledTask");
      }
      if (item.moduleNo == "0302") {
        this.$router.push("/message");
      }
      if (item.moduleNo == "0214") {
        this.$router.push("/TvChannel");
      }
    },
    homePage() {
      this.$router.push("/homePage");
    },
    quanxian() {
      this.$router.push("/quanxian");
    },
    jiudianguanli() {
      this.$router.push("/jiudianguanli");
    },
    // guanliyuan() {
    //   this.$router.push("/guanliyuan");
    // },
    xiaochengxu() {
      this.$router.push("/xiaochengxu");
    },
    // jiudianquanxian() {
    //   this.$router.push("/jiudianquanxian");
    // },
    qingkongtz() {
      this.$store.state.messageList = [];
      this.$store.state.messageReadCount = 0;
    },
    shouqitongzhi() {
      this.$store.state.hidetongzhi = true;
      var newMessage = this.$store.state.messageList;
      newMessage.forEach((item, index) => {
        item.read = 1;
        this.$store.state.messageList.splice(index, 1, item);
      });
      this.$store.state.messageReadCount = 0;
    },
    // 获取信息
    getMessageLength() {
      getRequest("partDeviceCount").then((res) => {
        if (res.status == 200) {
          this.messageNumber = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
  },
  mounted() {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.home-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-header {
  width: 100%;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 21, 41, 0.05);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.home-img {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #d6d6d6;
  overflow: hidden;
  margin: 0 8px 0 24px;
}
.home-LOGO {
  display: inline-block;
  color: #001529;
  font-size: 20px;
  margin-left: 10px;
}
.home-function {
  display: flex;
  position: absolute;
  right: 30px;
  font-size: 16px;
  align-items: center;
}
.home-function > i {
  margin: 0 10px;
}
.home-function > div {
  margin: 0 10px;
}
.portrait {
  display: flex;
  align-items: center;
}
.head-portrait {
  width: 24px;
  height: 24px;
  background: #a6dbff;
  border-radius: 50%;
  margin-right: 8px;
}
.home-container {
  flex: 1;
  overflow: auto;
  /* background: cyan; */
  display: flex;
  justify-content: space-between;
  position: relative;
}
.home-left {
  width: 230px;
  height: 100%;
  overflow: auto;
  padding-right: 17px;
  /* border-top: 1px solid rgba(0, 21, 41, 0.05); */
}
.el-menu-vertical-demo {
  height: 100%;
  overflow: auto;
}
.el-menu {
  background: #fff;
  border-right: none !important;
}
.el-submenu {
  background: #fff;
}
element.style {
  background: #fff;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(39, 2, 248, 0.1);
}

.home-right {
  flex: 1;
  height: 100;
  overflow: auto;
  background-color: #f5f6fa;
}
.el-submenu__title {
  font-weight: 400;
  color: #000000;
  opacity: 0.85;
  font-size: 18px;
}
.el-menu-item {
  border-left: 5px solid #fff;
  color: #000000;
  opacity: 0.65;
  font-size: 14px;
}
.el-menu-item.is-active {
  width: 100%;
  background: #ebf2ff;
  border-left: 5px solid #387dff;
}
>>> .el-icon-user {
  height: 24px;
  width: 24px;
}
.tfNotice_box {
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-height: 37em;
  overflow: hidden;
  overflow-y: hidden;
  width: 295px;
  display: inline-block;
  z-index: 9999999999;
  border-radius: 15px;
}
.tfNotice {
  border-radius: 8px;
  margin-bottom: 2em;
  max-height: 35em;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid rgb(250, 250, 250);
  /* height: 100%; */
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
}
.title1 {
  margin-bottom: 5px;
  font-size: 20px;
  color: #333;
}
.Notices_title span {
  cursor: pointer;
  font-size: 24px;
}
.Notices_title span:hover {
  transform: scale(1.5);
}
.Notices_title {
  display: flex;
  justify-content: space-between;
  line-height: 24px;
}
.Notice_contain {
  color: #6d798e;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
}
.Notice_dotype {
  color: #333;
}
.Notice_user {
  font-size: 18px;
  color: #bfbebe;
  text-align: center;
  width: 100%;
  line-height: 53px;
  font-weight: bold;
}
.Notices {
  background: #ffffffff;
  height: 100px;
  padding: 10px;
  border-bottom: 1px solid #dededf;
  position: relative;
}
.tfNotice_box .header {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #ccc;
  position: absolute;
  width: 90%;
  height: 2em;
  line-height: 2em;
  padding: 0 5%;
  bottom: 0;
  left: 0;
}
.hidetz {
  cursor: pointer;
}
.hidetz:hover {
  transform: scale(1.2);
}
.tzcount {
  color: #999999;
}
.xstongzhi {
  position: fixed;
  right: 0;
  bottom: 10%;
  width: 30px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 10px 5px;
  background: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  z-index: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: center;
}
.xstongzhi:hover {
  transform: scale(1.1);
  color: #4bb0ff;
}
.qingkong {
  color: red;
  font-size: 14px;
  cursor: pointer;
}
.qingkong:hover {
  transform: scale(2);
  font-size: 16px;
}
.home-tongzhi {
  position: fixed;
  right: 1%;
  top: 1%;
  width: 50px;
  cursor: pointer;
  z-index: 999;
}
.hotelName {
  font-weight: bold;
  margin-right: 10px;
}
.ic_bell {
  width: 16px;
  margin-right: 5px;
}

.newMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.newMessage2 {
  margin-left: 20px;
}
.delmessage {
  position: absolute;
  right: 18px;
  top: 2px;
  cursor: pointer;
}
.delmessage:hover {
  transform: scale(2);
  font-size: 16px;
}
.danger {
  color: Red;
}
.sqtz {
  cursor: pointer;
}
.float {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
}
</style>